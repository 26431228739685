const config = {
  SiteURL: process.env.SITE_URL || "http://localhost:3000",
  Environment: process.env.ENVIRONMENT,
  DatoApiToken: process.env.DATO_API_TOKEN,
  DatoEnv: process.env.DATO_ENV,
  DatoPreviewSecret: process.env.DATOCMS_PREVIEW_SECRET,
  GTMID: process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID,
  MapboxKey: process.env.NEXT_PUBLIC_MAPBOX_KEY,
  SentryDSN: process.env.SENTRY_DSN,
  NextPublicSentryDSN: process.env.NEXT_PUBLIC_SENTRY_DSN,
  SentryORG: process.env.SENTRY_ORG,
  SentryAuthToken: process.env.SENTRY_AUTH_TOKEN,
  SentryProject: process.env.SENTRY_PROJECT,
  MapBoxStyle: "alessio-re/clmgm0ifr01iy01pb6yu9fm4m",
};

module.exports = config;
